<template>
    <div class="overlay">
        <div class="overlay-container">
            <div class="overlay-header">
                <slot name="header"></slot>
            </div>
            <div class="overlay-content">
                <slot name="default" />
            </div>
            <div class="overlay-footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss">

    @use '@/resources/global.scss' as g;

    div.overlay {
        position: fixed;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0,0,0,0.2);
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        > .overlay-container {
            background: white;
            min-width: 80vw;
            max-width: 95vw;
            box-shadow: 7px 7px 18px 3px rgba(0, 0, 0, 0.75);
            overflow: hidden;
            display: grid;
            grid-template-rows: auto 1fr auto;
            > * {
                padding: 10px;
            }
            > .overlay-header {
                background: g.$color-primary-0;
                color: g.$color-primary-1;
            } 
            > .overlay-content {
                overflow: auto;
                background: g.$color-primary-1;
                color: g.$color-primary-4;
            } 
            > .overlay-footer {
                background: g.$color-primary-1;
                color: g.$color-primary-4;
                display: grid;
                grid-auto-flow: column;
                justify-content: flex-end;
                grid-column-gap: 2px;
            }                         
        }
    }

</style>
