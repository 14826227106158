<script setup lang="ts">
    import { computed, ComputedRef, onMounted, ref, Ref, useTemplateRef } from "vue";
    import ButtonWidget from "../../widgets/button-widget.vue";
    import BaseView from "../base-view.vue";
    import { queryTypeList } from "../neu/constants";

    import { AmpelErfassungEingabe, ampelService } from '@/services/ampelService';
    import { AmpelBacklistKonfiguration, backlistService } from '@/services/backlistService';
    import { type GetAmazonAccountsResponseItem, configService } from '@/services/configService';

    import { read, utils } from "xlsx";

    const amazonAccounts : Ref<GetAmazonAccountsResponseItem[]> = ref([]);
    const uploadInput = useTemplateRef<HTMLInputElement>("uploadInput");
    const selectedQueryType = ref(null);
    const amazonAccount = ref(null);

    interface ExcelLineType {
        EAN: string;
        Produktnummer: string;
        Menge: number;
        Sendungsnummer: string;
    };

    interface UploadGroup {
        name: string,
        backlist: AmpelBacklistKonfiguration,
        vorgang: AmpelErfassungEingabe
    }
    const uploadInputData : Ref<ExcelLineType[]> = ref();

    // Laden
    onMounted(async () => {
        amazonAccounts.value = await configService
            .getAmazonAccounts()
            .then(queryResult => queryResult?.items ?? []);
    })

    // Datei Upload
    const onUploadFileChange = async (event: Event) => {
        uploadInputData.value = null;
        try {
            // Exceldatei laden und nach JSON konvertieren
            const file = await uploadInput.value.files?.[0]?.arrayBuffer();
            const workbook = read(file, {type: 'binary'});
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            uploadInputData.value = utils.sheet_to_json<ExcelLineType>(worksheet, { blankrows: false });
        } catch (e) {
            alert(e);
        }
    }

    // Datei parsen und mit Einagen zu Commands verbasteln
    const parsedData : ComputedRef<UploadGroup[]>= computed(() => {
        
        // Gruppieren nach Sendungsnummer und mappen auf AmpelBacklistKonfiguration und 
        const grouped_data = (uploadInputData.value ?? []).reduce(
            (acc, item) => {
                if (!acc[item.Sendungsnummer]) {
                    acc[item.Sendungsnummer] = [];
                }
                acc[item.Sendungsnummer].push(item);
                return acc;
            }, 
            {} as Record<string, ExcelLineType[]>
        );

        // Mappen
        return Object.keys(grouped_data).map(key => {
            const value = grouped_data[key];
            const backlist : AmpelBacklistKonfiguration = {
                listenName: key,
                items: value.map(line => ({
                    queryType: selectedQueryType.value,
                    identifierType: 'ISBN',
                    itemId: line.EAN,
                    anzahlGruen: line.Menge,
                    anzahlGelb: 0,
                    anzahlRot: 0,
                    benutztGruen: 0,
                    benutztGelb: 0,
                    benutztRot: 0
                }))
            };
            const vorgang : AmpelErfassungEingabe = {
                identifierType: 'ISBN',
                queryType: selectedQueryType.value,
                versandOption: 'G',
                interneReferenz: key,
                backlistName: key,
                fbaInbound: true,
                fbaInboundPrintLabel: true,
                fbaInboundKonto: amazonAccount.value,
            } 
            return {
                name: key,
                backlist,
                vorgang
            } satisfies UploadGroup
        });
    })

    // Aktion durchführen
    const nextLog = ref([]);
    const canNext = computed(() => {
        return parsedData.value != null;
    })
    const onNext = async() => {

        if (!canNext.value) return;

        nextLog.value = [];
        for (const group of parsedData.value) {
            try {
                await backlistService.setzeKonfiguration(group.backlist);
                nextLog.value.push(`Backlist ${group.backlist.listenName} angelegt.`)
            }
            catch (e) {
                nextLog.value.push(`Backlist ${group.backlist.listenName} nicht angelegt. Fehler: ${e}`)
            }
            try {
                await ampelService.starteErfassung(group.vorgang);
                nextLog.value.push(`Vorgang ${group.vorgang.interneReferenz} angelegt.`)
            }
            catch (e) {
                nextLog.value.push(`Vorgang ${group.vorgang.interneReferenz} nicht angelegt. Fehler: ${e}`)
            }
        }

        // Clear
        selectedQueryType.value = null;
        amazonAccount.value = null;
        uploadInputData.value = null;

    }

</script>

<template>
    <BaseView>
        <template v-slot:header>
            <h1>JagerAM Auftrag Upload</h1>
        </template>  
        <div class="body">
            <div class="form-group">
                <label>Typ:</label>
                <select v-model="selectedQueryType">
                    <option v-for="option of queryTypeList" :value="option.value" :key="option.value">
                        {{ option.text }}
                    </option>
                </select>
            </div>  
            <div class="form-group">
                <label>Amazonkonto:</label>
                <select v-model="amazonAccount">
                    <option v-for="option in amazonAccounts" :value="option.sellerId" :key="option.accountName">
                        {{ option.accountName }} 
                    </option>
                </select>
            </div>         
            <div class="form-group">
                <label>Jager AM Datei:</label>
                <input type="file" 
                    ref="uploadInput" 
                    multiple="false"
                    @change="onUploadFileChange"
                />
            </div>  
            
            <div class="prepresult" v-if="parsedData?.length > 0">
                <label>Dateiinhalt</label>
                <ul>
                    <li v-for="g of parsedData" :key="g.name">
                        {{ g.name }} mit {{ g.backlist?.items?.length }} Backlist Einträgen
                    </li>
                </ul>
            </div>     
            
            <div class="worklog" v-if="nextLog?.length > 0">
                <label>Log</label>
                <ul>
                    <li v-for="logEntry of nextLog" :key="logEntry">
                        {{ logEntry }}
                    </li>
                </ul>
            </div>

        </div>
        <template v-slot:footer>
            <ButtonWidget 
                iconName="arrow_left" 
                text="zurück zum Einstieg" 
                @click="$router.push('/home')"/>
            <ButtonWidget 
                iconName="arrow_right" 
                text="Upload starten"  
                @click="onNext" 
                :disabled="!canNext"/>
        </template>        
    </BaseView>
</template>

<style lang="scss">
    div.prepresult {
        padding: 1rem;
        padding-left: 2rem;
        margin: 0.5rem;
        background-color: #eee;
    }
    div.worklog {
        padding: 1rem;
        padding-left: 2rem;
        margin: 0.5rem;
        background-color: #ddd;
    }
</style>