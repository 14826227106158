<script setup lang="ts">

    import { ICONNAME } from '@/assets/icons';
    import { defineAsyncComponent } from 'vue'

    const props = defineProps<{
        iconName: ICONNAME
    }>()

    const Icon = defineAsyncComponent(() => import(`../../assets/icons/${props.iconName}.svg`))

</script>

<template>
    <Icon @click="$emit('click', $event)" class="icon-widget" />
</template>
