<script setup lang="ts">

    import { ref } from "vue";
    import { get } from 'lodash';
    import { AmpelBacklistKonfiguration, backlistService } from '@/services/backlistService'

    const uploadInput = ref();
    let promiseResolve : (listName: string) => void = null;
    let promiseReject : (e: any) => void = null;

    const onUploadFileChange = () => {
        let inputElement = uploadInput.value;

        try {
            let file = inputElement.files[0];

            let reader = new FileReader();
            reader.onloadend = async (e) => {

                let csvContent = e.target.result as string;
                let csvLines = csvContent.split(/\r?\n/);
                csvLines.shift();   // Kopfzeile ignorieren

                let parsedData : AmpelBacklistKonfiguration = {
                    listenName: file.name,
                    items: csvLines
                        .filter(x => x != '')
                        .map(x => {
                            let cells = x.split(/;/);
                            return {
                                queryType: get(cells, 0),
                                identifierType: get(cells, 1),
                                itemId: get(cells, 2),
                                anzahlGruen: get(cells, 3),
                                anzahlGelb: get(cells, 4),
                                anzahlRot: get(cells, 5) ?? "0",
                                benutztGruen: 0,
                                benutztGelb: 0,
                                benutztRot: 0
                            }
                        })
                }

                backlistService
                    .setzeKonfiguration(parsedData)
                    .then(() => promiseResolve(file.name));
                    
            };
            reader.readAsText(file);	

        }
        catch (e) {
            promiseReject(e);
        }
        finally {
            inputElement.value = '';
        }

    };

    const triggerUpload = () : Promise<string> => {
        return new Promise((resolve, reject) => {
            promiseResolve = resolve;
            promiseReject = reject;
            uploadInput.value.click();
        })
    }

    defineExpose({
        triggerUpload
    });

</script>

<template>
    <input type="file" 
        ref="uploadInput" 
        @change="onUploadFileChange" 
        style="display: none; width: 0px; border: 0px; height: 0px;">
</template>