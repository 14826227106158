<script setup lang="ts">

    import { ICONNAME } from "@/assets/icons";
    import IconWidget from "./icon-widget.vue";
    
    interface ButtonEvents {
        (event: 'click'): void;
    }
    const emit = defineEmits<ButtonEvents>();

    export interface ButtonProps {
        iconName : ICONNAME;
        text : string;
        iconOnly ?: boolean;
        disabled ?: boolean;
    }
    const props = defineProps<ButtonProps>();

    function onClick() {
        if (!props.disabled) {
            emit('click');
        }
    };

    function onKeyup(evt) {
        if (evt.keyCode === 13) {
            this.onClick();
        }
    };

</script>

<template>
    <div class="button-widget" :class="{ disabled: disabled, 'icon-only': iconOnly }" 
        tabindex="0" 
        @click="onClick" @keyup="onKeyup"
        v-tooltip="text"
    >
        <div class="icon">
            <IconWidget v-if="iconName" :iconName="iconName" />
        </div>
        <div class="text">
            {{ text }}
        </div>
    </div>
</template>

<style lang="scss">
    @use '@/resources/global.scss' as g;
    .button-widget {
        display: grid;
        grid-auto-flow: row;
        justify-items: center;
        align-items: center;
        min-width: 128px;
        width: auto;
        max-width: 192px;
        > .icon {
            height: 48px;
            width: 48px;
            svg {
                height: 100%;
                width: 100%;
                fill: #4284F4;
            }
        }
        > .text {
            font-size: 14px;
            max-width: 100%;
            @include g.textEllipsisOnOverflow();
        }
        padding: 6px;
        margin: 4px;
        border: 1px solid #4284F4;
        background-color: white;
        color: #4284F4;
        cursor: pointer;
        &.disabled {
            color: lightgray;
            border: 1px solid lightgray;
            cursor: not-allowed;
            > .icon {
                svg { 
                    fill: lightgray;
                }
            }
        }
        &.icon-only {
            width: 64px;
            height: 64px;
            .text {
                display: none;
            }
        }
    }
</style>