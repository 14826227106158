<template>
    <div class="base-view">
        <nav>
            <img class="logo" src="/logo.png">
            <h1>APIS Wareneingangsampel</h1>
        </nav>
        <header>
            <slot name="header" />
        </header>
        <main>
            <slot />
        </main>
        <footer>
            <slot name="footer" />
        </footer>
    </div>  
</template>

<style lang="scss">
    @use '@/resources/global.scss' as g;
    div.base-view {
        display: grid;
        overflow: hidden;
        grid-template-rows: auto auto 1fr auto;
        position: relative;
        > nav {
            display: grid;
            grid-auto-flow: column;
            justify-content: center;
            align-items: center;
            background: g.$color-primary-2;
        }
        > header {
            display: grid;
            grid-auto-flow: column;
            background: g.$color-primary-0;
        }
        > main {
            display: grid;
            overflow: auto;
        }
        > footer {
            display: grid;
            grid-template-columns: repeat(auto-fit, 128px);
            grid-gap: 4px;
            justify-content: center;
            background: g.$color-primary-2;
        }
    }

    div.base-view {
        font-size: 20px;
    }

    div.base-view > nav {
        padding: 0px 5px;
        > h1 {
            margin: 6px;
            font-size: 28pt;
            @include g.textEllipsisOnOverflow();
        }
        > img.logo {
            width: 32px;
            height: 32px;
        }
    } 

    div.base-view > header {
        h1 {
            margin: 6px;
            font-size: 28pt;
            text-align: center;
            @include g.textEllipsisOnOverflow();
        }
    }

    div.base-view > main {
        .body {
            overflow: auto;
        }
    }

</style>