<script setup lang="ts">

    import { Ref, ref, computed, unref, onMounted } from 'vue';
    import { credentialsService } from '@/services/credentialsService';
    import { ampelService } from '@/services/ampelService'
    import { configService } from '@/services/configService'
    import { backlistService } from '@/services/backlistService'
    import { useRouter } from 'vue-router';
    import { queryTypeList, idTypeList, versandOptionList } from "../neu/constants"

    import BaseView from "../base-view.vue";
    import ButtonWidget from "../../widgets/button-widget.vue";
    
    const props = defineProps({
        vorgangsId: String
    })

    const router = useRouter();

    const amazonAccounts = ref([])
    const backlistList = ref([]);

    interface AmpelVorgang {
        vorgangsId: string,
        jobName: string,
        createdAt: string,
        finishedAt: string,
        identifierType: string,
        versandOption: string,
        backlistName: string,
        queryType: string,
        fbaInbound: boolean,
        fbaInboundKonto: string,
        fbaInboundPrintLabel: boolean,
        jagerBestellNummer: string
    }
    
    const ampelVorgang : Ref<AmpelVorgang> = ref();

    const interneReferenzRegEx = computed({
        get() {
            return ampelVorgang.value?.jobName;
        },
        set(newValue) {
            var valueToWrite = newValue.match('[a-zA-Z0-9\-_ ]*');  
            if (valueToWrite && valueToWrite.length > 0) {
                let vorgang = unref(ampelVorgang)
                vorgang.jobName = '';
                vorgang.jobName = valueToWrite[0];
            } 
        }
    });

    const canSave = computed(() => {
        if (!canEdit.value)
            return false;
        if (!ampelVorgang.value?.jobName) 
            return false;
        return true;
    });

    const canEdit = computed(() => {
        let hasBase = credentialsService.hasAmpelBasePrivilege();
        let hasConfig = credentialsService.hasAmpelConfigPrivilege();
        let hasJager = credentialsService.hasAmpelJagerPrivilege();
        return (hasBase || hasConfig) && !hasJager;
    });

    const next = async () => {
        if (canSave.value == false)
            return;
        
        let v = ampelVorgang.value;
        let cmd = { 
            vorgangsId: v.vorgangsId,
            jobName: v.jobName,
            backlistName: v.backlistName,
            identifierType: v.identifierType,
            versandOption: v.versandOption,
            queryType: v.queryType,
            fbaInbound: v.fbaInbound,
            fbaInboundKonto: v.fbaInboundKonto,
            fbaInboundPrintLabel: v.fbaInboundPrintLabel,
            jagerBestellNummer: v.jagerBestellNummer
        };

        await ampelService
            .bearbeiteErfassung(cmd)
            .then(() => router.go(-1));
    };

    onMounted(async () => {
        amazonAccounts.value = await configService
            .getAmazonAccounts()
            .then(queryResult => {
                return queryResult?.items;
            });
        backlistList.value = await backlistService
            .abfrage()
            .then(queryResult => {
                return queryResult?.items?.map(row => ({
                    id: row.id,
                    name: row.name
                }));
            });            
        ampelVorgang.value = await ampelService
            .listeErfassungen({
                aktiveJobs: true, 
                beendeteJobs: true, 
                filterJobIds: [
                    props.vorgangsId
                ]
            })
            .then(result => {
                return result?.vorgaenge?.[0]; 
            });
    });
    
</script>

<template>
    <BaseView>
        <template v-slot:header>
            <h1>Erfassung bearbeiten</h1>
        </template>     
        <div class="body" v-if="ampelVorgang">
            <div class="form-group">
                <label>ID Typ:</label>
                <select v-model="ampelVorgang.identifierType" :disabled="!canEdit">
                    <option v-for="option in idTypeList" :value="option.value" :key="option.value">
                        {{ option.text }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>Typ:</label>
                <select v-model="ampelVorgang.queryType" :disabled="!canEdit">
                    <option v-for="option in queryTypeList" :value="option.value" :key="option.value">
                        {{ option.text }}
                    </option>
                </select>                
            </div>            
            <div class="form-group">
                <label>interne Referenz</label>
                <input type="text" autofocus 
                    :disabled="!canEdit"
                    v-model="interneReferenzRegEx"  
                    v-on:keyup.enter="next" />    
            </div>     
            <div class="form-group">
                <label>Bestellnummer Shopware:</label>
                <input type="text" 
                    :disabled="!canEdit"
                    v-model="ampelVorgang.jagerBestellNummer"  
                    v-on:keyup.enter="next" /> 
            </div>
            <div class="form-group">
                <label>Aufteilung:</label>
                <select v-model="ampelVorgang.backlistName" :disabled="!canEdit">
                    <option v-for="option in backlistList" :value="option.name" :key="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>Versandoption:</label>
                <select v-model="ampelVorgang.versandOption" :disabled="!canEdit">
                    <option v-for="option in versandOptionList" :value="option.value" :key="option.value">
                        {{ option.text }}
                    </option>
                </select>
            </div>            
            <div class="form-group" v-if="canEdit">
                <label>Daten bei Amazon anlegen</label>
                <select v-model="ampelVorgang.fbaInbound" :disabled="!canEdit">
                    <option :value="true">Ja</option>
                    <option :value="false">Nein</option>
                </select>                
            </div>   
            <div class="form-group">
                <label>FNSKU Labels drucken:</label>
                <select v-model="ampelVorgang.fbaInboundPrintLabel" :disabled="!canEdit">
                    <option :value="true">Ja</option>
                    <option :value="false">Nein</option>
                </select>  
            </div>            
            <div class="form-group">
                <label>Amazonkonto:</label>
                <select v-model="ampelVorgang.fbaInboundKonto" :disabled="!canEdit">
                    <option v-for="option in amazonAccounts" :value="option.sellerId" :key="option.accountName">
                        {{ option.accountName }} 
                    </option>
                </select>
            </div>                                     
        </div>
        <template v-slot:footer>
            <ButtonWidget
                iconName="arrow_left" 
                text="zurück" 
                @click="$router.go(-1)"/>
            <ButtonWidget
                iconName="arrow_right" 
                text="Änderungen speichern"  
                @click="next" 
                :disabled="!canSave"/>
        </template>
    </BaseView>
</template>