import {createApp, nextTick} from 'vue'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import { credentialsService } from '@/services/credentialsService';
await credentialsService.init();

import { router } from "./router";
import AppShell from "./components/shells/app-shell.vue";
import "./resources/style.css";

const app = createApp(AppShell);
app.use(router);
app.use(FloatingVue);
app.directive('focus', {
    beforeMount(el, binding) {
        if (binding.value === true) {
            nextTick(() => {
                el.focus();
            })
        }
    }
});
app.mount('#app');