import { axiosClient}  from "./fetchHelper.js";
import Keycloak from "keycloak-js";

class CredentialsService {
    
    keycloak: Keycloak;
    refreshTimer: number;
    
    async init() {

        /**
        * Keycloak Config laden
        */
        const keycloakConfig = await axiosClient
            .get("/api/public/keycloak")
            .then(response => response.data);

        /** Keycloak Client initialisieren
         * @see https://www.keycloak.org/docs/latest/securing_apps/index.html#javascript-adapter-reference 
         */
        this.keycloak = new Keycloak({
            url: keycloakConfig.serverUrl,
            realm: keycloakConfig.realm,
            clientId: "ampel"
        });
        
        /**
         * Login mit evtl. bestehdendem Cookie durchführen
         */
        await this.keycloak.init({
            onLoad: 'login-required',
            responseMode: 'query'
        }).then (loggedIn => {
            console.debug(`Keycloak User LoggedIn ${loggedIn}`);
            axiosClient.defaults.headers['Authorization'] = `Bearer ${this.keycloak?.token}`;
        }).catch(error => {
            console.error(error);
            axiosClient.defaults.headers['Authorization'] = null;
        });

        /**
         * AutoRefresh des Token
         */
        this.refreshTimer = setInterval(
            async () => {
                await this.keycloak?.updateToken(70)
                    .then(refreshed => {
                        console.debug(`Keycloak Token ${refreshed ? '' : 'not '}refreshed`);
                        axiosClient.defaults.headers['Authorization'] = `Bearer ${this?.keycloak?.token}`;
                    })
                    .catch(error => {
                        console.error(error);
                        axiosClient.defaults.headers['Authorization'] = null;
                    });   
            }, 
            15000
        );
    }

    isLoggendIn() {
        return Boolean(this.keycloak?.authenticated);
    }

    authorities() {
        return this.keycloak?.realmAccess?.roles ?? [];
    }
    

    hasAmpelConfigPrivilege() {
        return Boolean(this.authorities().find(x => x == 'AMPEL_CONFIG'))
    }

    hasAmpelJagerPrivilege() {
        return Boolean(this.authorities().find(x => x == 'AMPEL_JAGER'));
    }

    hasAmpelJagerAdminPrivilege() {
        return Boolean(this.authorities().find(x => x == 'AMPEL_JAGER_ADMIN'));
    }


    hasAmpelBasePrivilege() {
        return Boolean(this.authorities().find(x => x == 'AMPEL_BASE'));
    }
}

export const credentialsService = new CredentialsService();