import { fetchHelper } from "./fetchHelper.ts";

const idCheckRegEx = /[^0-9a-zA-Z]/g;
const fnSanitizeId = function(id) {
    return String(id).replace(idCheckRegEx, "");
}

export interface AmpelErfassungEingabe {
    identifierType: string
    queryType: string
    versandOption: string
    interneReferenz: string
    backlistName: string
    fbaInbound: boolean
    fbaInboundKartonFeed?: boolean
    fbaInboundKonto?: string
    fbaInboundPrintLabel?: boolean
    jagerBestellNummer?: string
}
export interface AmpelErfassungEingabeResult {
    vorgangsId: string
}

export interface AmpelErfassungListeQuery {
    aktiveJobs: boolean
    beendeteJobs: boolean
    filterJobIds ?: string[]
}
export interface AmpelErfassungListeQueryResult {
    vorgaenge: AmpelErfassungListeQueryResultItem[]
}
export interface AmpelErfassungListeQueryResultItem {
    vorgangsId: string
    jobName: string
    createdAt: string
    finishedAt: string
    identifierType: string
    backlistName: string
    versandOption: string
    queryType: string
    fbaInbound: boolean
    fbaInboundKonto: string
    fbaInboundPrintLabel: boolean
    jagerBestellNummer: string
}

export interface AmpelErfassungAbschliessenCommand {
    vorgangsId: string
}
export interface AmpelErfassungAbschliessenCommandResult {
    anzahlKlasseRot : number	
    anzahlKlasseGelb : number	
    anzahlKlasseGruen : number	
    anzahlKlasseNichtGefunden: number 
    anzahlTitelRot : number 
    anzahlTitelGelb : number 
    anzahlTitelGruen : number 
    anzahlTitelNichtGefunden : number
}

export const ampelService = {
    listeErfassungen: (params: AmpelErfassungListeQuery) : Promise<AmpelErfassungListeQueryResult> => {
        if (!params.hasOwnProperty("aktiveJobs"))
            throw new Error("aktiveJobs missing");
        if (!params.hasOwnProperty("beendeteJobs"))
            throw new Error("beendeteJobs missing");     

        return fetchHelper('listeErfassungen', {
            aktiveJobs: (params.aktiveJobs),
            beendeteJobs: (params.beendeteJobs),
            filterJobIds: params.filterJobIds
        });
    },
    starteErfassung: (params : AmpelErfassungEingabe) : Promise<AmpelErfassungEingabeResult> => {
        if (!params.hasOwnProperty("identifierType"))
            throw new Error("identifierType missing");
        if (!params.hasOwnProperty("queryType"))
            throw new Error("queryType missing");
        if (!params.hasOwnProperty("interneReferenz"))
            throw new Error("interneReferenz missing");
        return fetchHelper('starteErfassung', params);
    },
    bearbeiteErfassung: (params) => {
        if (!params.hasOwnProperty("jobName"))
            throw new Error("jobName missing");
        if (!params.hasOwnProperty("vorgangsId"))
            throw new Error("vorgangsId missing");   
        if (!params.hasOwnProperty("queryType"))
            throw new Error("versandOption missing");   
        if (!params.hasOwnProperty("versandOption"))
            throw new Error("vorgangsId missing");   
        return fetchHelper('bearbeiteErfassung', params);         
    },
    leseKartonStatistik: (params) => {
        if (!params.hasOwnProperty("vorgangsId"))
            throw new Error("vorgangsId missing");
        return fetchHelper('leseKartonStatistik', params);
    },
    vorverarbeiteEingabe: (params) => {
        if (!params.hasOwnProperty("vorgangsId"))
            throw new Error("vorgangsId missing");
        if (!params.hasOwnProperty("id"))
            throw new Error("id missing");
        params.id = fnSanitizeId(params.id);
        return fetchHelper('vorverarbeiteEingabe', params); 
    },
    verarbeiteEingabe: (params) => {
        if (!params.hasOwnProperty("vorgangsId"))
            throw new Error("vorgangsId missing");
        if (!params.hasOwnProperty("id"))
            throw new Error("id missing");
        if (!params.hasOwnProperty("anzahl"))
            throw new Error("anzahl missing");
        params.id = fnSanitizeId(params.id);
        return fetchHelper('verarbeiteEingabe', params); 
    },
    beendeErfassung: (params: AmpelErfassungAbschliessenCommand) : Promise<AmpelErfassungAbschliessenCommandResult> => {
        if (!params.hasOwnProperty("vorgangsId"))
            throw new Error("vorgangsId missing");
        return fetchHelper('beendeErfassung', params); 
    },
    reaktiviereErfassung: (params) => {
        if (!params.hasOwnProperty("vorgangsId"))
            throw new Error("vorgangsId missing");
        return fetchHelper('reaktiviereErfassung', params); 
    },    
    eingabeHistorie: (params) => {
        if (!params.hasOwnProperty("vorgangsId"))
            throw new Error("vorgangsId missing");
        if (!params.hasOwnProperty("kartonNummer"))
            throw new Error("kartonNummer missing");
        return fetchHelper('eingabeHistorie', params); 
    }, 
    loescheEingabe: (params) => {
        if (!params.hasOwnProperty("vorgangsId"))
            throw new Error("vorgangsId missing");
        return fetchHelper('loescheEingabe', params); 
    }
};